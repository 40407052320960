import { Col, Row, Button, Input } from 'antd';
import { FormikProps, withFormik } from "formik";
import { useNavigate, useParams } from 'react-router-dom';
import { CustomizeCatalogueProps } from "../types";
import { Dispatch } from "@reduxjs/toolkit";
import InputField from "../../common/components/InputField";
import styles from './templateSelector.module.scss';
import stylesButton from './filterSupplierVindix.module.scss';
import * as yup from "yup";
import template1 from "../../common/img/vindix/catalogue/template_1_preview_empty.png"
import template2 from "../../common/img/templateCatalogo12.png"
import template3 from "../../common/img/templateCatalogo13.png"

import { EyeOutlined, BlockOutlined, CloudUploadOutlined, LogoutOutlined, UserOutlined, UploadOutlined, FilePdfOutlined } from '@ant-design/icons';
import { catalogueSlice } from '../../../redux/reducers/catalogue';

interface customCatalogueProps {
    dispatch: (value: any) => void;
    navigate: (value: string) => void;
  }

const CustomizeTemplate = ({handleSubmit,
    values,
    setFieldTouched,
    touched,
    errors,
    dispatch,
    setFieldValue, navigate}: FormikProps<CustomizeCatalogueProps> & customCatalogueProps) => {
    const urlParams = useParams();
    const currentTemplate = () => { 
        switch(urlParams?.template) {
            case 'template1': return template1; break;
            case 'template2': return template2; break;
            case 'template3': return template3; break;
            default: return template3; break;
       };
    }

    // const navigate = useNavigate();

    const handleCatalogueClick = (catalogue: string) => {
        navigate('/catalogue/customize/' + catalogue)
    }

    const goSelectWines = () => {
        let templateData = {
            template: urlParams?.tempalte,
            title: values.title,
            website: values.website,
            logo: values.logo,
            info: values.info,
        }
        sessionStorage.setItem("template", JSON.stringify(templateData));

        navigate('/catalogue/select-wines/' + urlParams?.template)
    }

    const renderCustomFields = (values) => {
        switch(urlParams?.template) {
            case 'template1': return renderCustomFieldsTemplate1(values); break;
            // case 'template2': return template2; break;
            // case 'template3': return template3; break;
            default: return <div style={{position: 'absolute', top: '7px', width: '46%', left: '28%', textAlign: 'center', color: '#fff', fontSize: '20px'}}>{values.title}</div>; break;
       };
    }

    const renderCustomFieldsTemplate1 = (values) => {
        return <>
            <div style={{position: 'absolute', top: '7px', width: '46%', left: '28%', textAlign: 'center', color: '#fff', fontSize: `${values.fontSizeTitle}px`}}>{values.title}</div>
            <div style={{position: 'absolute', bottom: '24%', width: '46%', left: '28%', textAlign: 'center', color: '#fff', fontSize: `${values.fontSizeWebsite}px`, textShadow: '0 2px black'}}>{values.website}</div>
            
            
        </>
    }

    return (
        <form
      onSubmit={handleSubmit}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleSubmit();
        }
      }}
      className={styles.wineForm}
    >
            <Row style={{background: '#fff', padding: '20px 0'}} className={styles.wineForm}>
                <Col span={24}>
                    <h1 className={styles.formTitle2}>2. Customize template</h1>
                </Col>
                <Col span={8} style={{cursor: 'pointer'}} >

                    { 
                        renderCustomFields(values)
                    }
                    <img src={currentTemplate()} style={{width: '261px'}}/>
                    <br/>
                </Col>
                <Col span={9} >
                    <InputField
                        title="Title"
                        wrapperClassName={styles.inputInfo}
                        titleClassName={styles.fieldTitleWine}
                        value={values.title}
                        defaultValue={'Catálogo de vinos 2024'}
                        required={false}
                        style={{color: '#000000'}}
                        onBlur={() => setFieldTouched("title")}
                        onChange={(event: any) => {
                            setFieldValue("title", event.target.value);
                            //cleareAuthValidationError();
                        }}
                    />
                    <InputField
                        title="Website"
                        wrapperClassName={styles.inputInfo}
                        titleClassName={styles.fieldTitleWine}
                        value={values.website}
                        required={false}
                        style={{color: '#000000'}}
                        onBlur={() => setFieldTouched("website")}
                        onChange={(event: any) => {
                            setFieldValue("website", event.target.value);
                            //cleareAuthValidationError();
                        }}
                    />
                    <InputField
                        title="Upload logo"
                        wrapperClassName={styles.inputInfo}
                        titleClassName={styles.fieldTitleWine}
                        value={values.logo}
                        required={false}
                        style={{color: '#000000'}}
                        onBlur={() => setFieldTouched("logo")}
                        onChange={(event: any) => {
                            setFieldValue("logo", event.target.value);
                            //cleareAuthValidationError();
                        }}
                    />
                    <InputField
                        title="Information"
                        wrapperClassName={styles.inputInfo}
                        titleClassName={styles.fieldTitleWine}
                        value={values.info}
                        required={false}
                        style={{color: '#000000'}}
                        onBlur={() => setFieldTouched("info")}
                        onChange={(event: any) => {
                            setFieldValue("info", event.target.value);
                            //cleareAuthValidationError();
                        }}
                    />
                </Col>
                <Col span={6}>
                    <InputField
                        title="Font size"
                        type='number'
                        wrapperClassName={styles.inputInfo}
                        titleClassName={styles.fieldTitleWine}
                        value={values.fontSizeTitle}
                        defaultValue={20}
                        required={false}
                        style={{color: '#000000'}}
                        onBlur={() => setFieldTouched("fontSizeTitle")}
                        onChange={(event: any) => {
                            setFieldValue("fontSizeTitle", event.target.value);
                            //cleareAuthValidationError();
                        }}
                    />
                    <InputField
                        title="Font size"
                        type='number'
                        wrapperClassName={styles.inputInfo}
                        titleClassName={styles.fieldTitleWine}
                        value={values.fontSizeWebsite}
                        defaultValue={20}
                        required={false}
                        style={{color: '#000000'}}
                        onBlur={() => setFieldTouched("fontSizeWebsite")}
                        onChange={(event: any) => {
                            setFieldValue("fontSizeWebsite", event.target.value);
                            //cleareAuthValidationError();
                        }}
                    />
                    <InputField
                        title="Logo size"
                        type='number'
                        wrapperClassName={styles.inputInfo}
                        titleClassName={styles.fieldTitleWine}
                        value={values.sizeLogo}
                        defaultValue={20}
                        required={false}
                        style={{color: '#000000'}}
                        onBlur={() => setFieldTouched("sizeLogo")}
                        onChange={(event: any) => {
                            setFieldValue("sizeLogo", event.target.value);
                            //cleareAuthValidationError();
                        }}
                    />
                    <InputField
                        title="Font size"
                        type='number'
                        wrapperClassName={styles.inputInfo}
                        titleClassName={styles.fieldTitleWine}
                        value={values.fontSizeInfo}
                        defaultValue={20}
                        required={false}
                        style={{color: '#000000'}}
                        onBlur={() => setFieldTouched("fontSizeInfo")}
                        onChange={(event: any) => {
                            setFieldValue("fontSizeInfo", event.target.value);
                            //cleareAuthValidationError();
                        }}
                    />
                <div style={{textAlign: 'right'}}>
                    <Button style={{backgroundColor: '#8F0A0E', borderRadius: '8px', border: '2px solid #8F0A0E', color: 'white'}} onClick={() => {setFieldValue("template", urlParams?.template); handleSubmit()}}>Next</Button>
                </div>
                </Col>
            </Row>
        </form>
    );
};

const CustomizeTemplateFormik = withFormik<customCatalogueProps, CustomizeCatalogueProps>({
    enableReinitialize: true,
    mapPropsToValues: () => ({
      title: "Catálogo de vinos 2024",
      website: 'www.vindix.eu',
      info: '',
      logo: '',
      template: '',
      fontSizeTitle: 20,
      fontSizeWebsite: 20,
      sizeLogo: 20,
      fontSizeInfo: 20,
    }),
    handleSubmit: (values, { props: { dispatch, navigate } }) => {
        console.log(values);
        dispatch(catalogueSlice.actions.setCustomizationData(values));
        navigate('/catalogue/select-wines/' + values.template)
    },
    validationSchema: yup.object().shape({
      title: yup
        .string()
        .required("This field is required"),
    }),
    displayName: "ForgotForm",
  })(CustomizeTemplate);
  
  export default CustomizeTemplateFormik;