import { FC, useState, useEffect } from "react";
import AppLayout from "../../common/components/AppLayout/vindix";
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from '../../../hooks/useAppSelector';
import { GoldOutlined } from '@ant-design/icons';
import { Checkbox, Pagination, Table, Button, Spin } from 'antd';
import { useActions } from '../../../hooks/useActions';
import { DeletionActivateDeactiveItemProps } from '../../items/types';
import { convertToItemFormFieldsWithSize } from '../../itemForm/utils/convertToItemFormFieldsWithSize';
import { ItemFormTabIds } from '../../itemForm/constants';
import { getItemFormFieldsByCompanyId } from '../../../api/itemForm';
import { ItemCatalog } from "../../items/components/ItemCatalog";
import template1 from "../../common/img/vindix/catalogue/template_1_preview_empty.png"
import template2 from "../../common/img/templateCatalogo12.png"
import template3 from "../../common/img/templateCatalogo13.png"
import {
    getRetailerItemByGtin,
    getDraftItemById,
    getDraftItemsByIdArray,
    getAllActiveItems,
    getAllDraftItems,
    getAllActiveItemsForView,
} from '../../../api/items';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { renderToString } from 'react-dom/server';
import { ReactComponent as ActiveItemIcon } from '../../common/icons/active.svg';
import style from "./catalogueVindixPage.module.scss"
import styles from '../../items/components/ActiveItemsList/activeItemsList.module.scss';
import { current } from "@reduxjs/toolkit";
import { relative } from "path";

const CataloguePreviewVindix: FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { userCompanyId, userCompanyType } = useAppSelector((state) => state.companies);
    const { user } = useAppSelector((state) => state.auth);
    const urlParams = useParams();

    const { activeItem } = useAppSelector((state) => state.activeItems);
    const { draftItem } = useAppSelector((state) => state.draftItems);
    const [isMainFieldsFull, setIsMainFieldsFull] = useState<any>(false);
    const [isLastTab, setIsLastTab] = useState<boolean>(false);
    const { itemFormFields } = useAppSelector((state) => state.itemForm);
    const [activeTabs, setActiveTabs] = useState<any>([]);
    const [currentItem, setCurrentItem] = useState<number>(-1);
    
    const selectedTab: any = useAppSelector(
        (state) => state.modals.draftItemsFormModalActiveTab
    );
    const element = itemFormFields?.find(
        (elem) => elem.name === selectedTab
    );

    const { title } = useAppSelector((state) => state.catalogue);
    const { website } = useAppSelector((state) => state.catalogue);
    const { logo } = useAppSelector((state) => state.catalogue);
    const { info } = useAppSelector((state) => state.catalogue);
    const { wines } = useAppSelector((state) => state.catalogue);
    const { fontSizeTitle } = useAppSelector((state) => state.catalogue);
    const { fontSizeWebsite } = useAppSelector((state) => state.catalogue);
    const filter = {gtins: wines, page: 1, paginate: 100}//useAppSelector((state) => state.additionalFilter);
    
    const currentTemplate = () => { 
        switch(urlParams?.template) {
            case 'template1': return template1; break;
            case 'template2': return template2; break;
            case 'template3': return template3; break;
            default: return template3; break;
       };
    }

    const printDocument = () => {
        const input = document.querySelectorAll('[ref-page="catalogueContent"]')[0].childNodes;
        //const input = document.querySelectorAll('[ref-page="catalogueCon"]')[0];//document.getElementById('divToPrint');
        const pdf = new jsPDF('l', 'mm', 'a4', true);

            html2canvas(input[0] as HTMLElement)
            .then((canvas) => {
                console.log('0')
                console.log(input[0])
                let i = 1;
                var ing = input[0] as HTMLElement
                //ing.style.zIndex = '99'
                const imgData = canvas.toDataURL('image/png');
                pdf.addImage(imgData, 'JPEG', 0, 0, 300, 0, '', 'FAST', 0);
                
                if(input.length > 1) {
                    addPage(pdf, input, i);
                } else {
                    pdf.save("download.pdf");
                }
            });
        
        
        
      }

    const addPage = (pdf: any, input, i) => {
        console.log(i)
        console.log(input[i])
        console.log("---- SECOND ITERATION ----")
        html2canvas(input[i] as HTMLElement)
        .then((canvas) => {
            console.log(i)
            console.log(input[i])
            //input[i].style.zIndex = 99 + i
            const imgData = canvas.toDataURL('image/png');
            pdf.addPage('a4', 'l');
            pdf.addImage(imgData, 'JPEG', 0, 0, 300, 0, '', 'FAST', 0);
            
            i++
            if(input.length < i) {
                addPage(pdf, input, i)
            } else {
                pdf.save("download.pdf");
            }
        })
    }

    const {
        setAdditionalFilter,
        setActiveItemsForDeactivationDeletion,
        cleareActiveItemStore,
        cleareDraftItemStore,
        setIsOpenDraftItemsFormModal,
        setModalActiveTab,
    } = useActions();

    const {
        activeItems,
        totalItems,
        isLoading,
        activeItemsForDeactivationDeletion,
    } = useAppSelector((state) => state.activeItems);

    const {
        draftItems
    } = useAppSelector((state) => state.draftItems);

    const { isChangeItemsDeletionActivationActions } = useAppSelector(
        (state) => state.modals
    );

    const goCataloguePreview = () => {
        navigate('/catalogue/preview/' + urlParams?.template)
    }

    

    const changePage = (change) => {
        let newPage = currentItem + change
        if(newPage < -1) {
            newPage = -1;
        }
        if(newPage > wines.length + 1) {
            newPage = wines.length + 1;
        }
        setCurrentItem(newPage)
        //printDocument()
    }

    // useEffect(() => {
    //     if (!user?.is_admin) {
    //         dispatch(getAllActiveItems(userCompanyId as number, filter));
    //         dispatch(getAllDraftItems(userCompanyId as number, filter));
    //     } else {
    //         dispatch(getAllActiveItemsForView(filter));
    //     }
    // }, [
    //     dispatch,
    //     filter,
    //     isChangeItemsDeletionActivationActions,
    //     user?.is_admin,
    //     userCompanyId,
    // ]);
    
    // useEffect(() => {
    //     if (!itemFormFields) return;
    //     let array: any = [];
    //     itemFormFields.forEach((elem: any) => {
    //         if (
    //             elem.name === 'main' ||
    //             elem.name === 'measurements' ||
    //             elem.name === 'digital assets' ||
    //             elem?.items?.length
    //         ) {
    //             array.push(elem);
    //         }
    //     });
    //     setActiveTabs(array);
    // }, [itemFormFields]);

    useEffect(() => {
        if(currentItem > -1) {
            if(typeof wines[currentItem] !== undefined) {
                console.log("Downloading draft item...");
                //dispatch(getDraftItemById(wines[currentItem]));
                //dispatch(getDraftItemsByIdArray(wines, userCompanyId as number));
                //dispatch(getAllDraftItems(userCompanyId as number, filter));
            }
        }
    }, [currentItem, wines]);

    // useEffect(() => {
    //     if (itemFormFields) {
    //         dispatch(setModalActiveTab(itemFormFields[0].name));
    //     }
    // }, [itemFormFields]);

    let itemFormFieldsMain =
        itemFormFields &&
        convertToItemFormFieldsWithSize(
            itemFormFields?.filter(
                (field) => field.item_tab_id === ItemFormTabIds.MAIN
            )
        );

    let blocksCount = itemFormFieldsMain
        ? Math.ceil((itemFormFieldsMain.length - 10) / 15) + 1
        : 0;

    useEffect(() => {
        if (userCompanyId) {
            dispatch(getItemFormFieldsByCompanyId(String(userCompanyId)));
            //dispatch(getAllDraftItems(userCompanyId as number, filter));
            dispatch(getDraftItemsByIdArray(wines, userCompanyId as number));
        }
    }, [dispatch, userCompanyId]);

  const steps = [
    {
      title: 'Template',
      content: 'First-content',
    },
    {
      title: 'Customize',
      content: 'Second-content',
    },
    {
      title: 'Select products',
      content: 'Last-content',
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const renderCustomFields = (values) => {
        switch(urlParams?.template) {
            case 'template1': return renderCustomFieldsTemplate1(values); break;
            // case 'template2': return template2; break;
            // case 'template3': return template3; break;
            default: return <div style={{position: 'absolute', top: '7px', width: '46%', left: '28%', textAlign: 'center', color: '#fff', fontSize: '20px'}}>{values.title}</div>; break;
        };
    }

    const renderCustomFieldsTemplate1 = (values) => {
        return <>
            <div style={{position: 'absolute', top: '2%', width: '26%', left: '38%', textAlign: 'center', color: '#fff', fontSize: `${values.fontSizeTitle * 2}px`}}>{values.title}</div>
            <div style={{position: 'absolute', bottom: '12%', width: '46%', left: '28%', textAlign: 'center', color: '#fff', fontSize: `${values.fontSizeWebsite * 2}px`, textShadow: '0 2px black'}}>{values.website}</div>
            
            
        </>
    }
  
  return (
    <AppLayout
      title="Catalogues"
      headerLogo={<GoldOutlined />}
    > 
        {/* <ActiveItemsFilter
            totalDraftItemsCount={totalItems}
            countCheckedActiveItems={
                activeItemsForDeactivationDeletion.length
            }
        /> */}

        <Button onClick={() => changePage(-1)} style={{position: 'absolute', zIndex: draftItems.length * 2, top: '50%', marginLeft: '-20px', height: '40px', borderRadius: '30px'}}>{'<'}</Button>
        
        { currentItem >= -1 && draftItems.length > 0 && draftItems[0].item_forms ?
            <div ref-page="catalogueContent" style={{position: 'relative'}}>

            { currentItem >= 0 ?
            
                 draftItems.map((item, key) => {

                    return <div style={{position: 'absolute', left: '0', top: '0', width: '100%', zIndex: currentItem === key ? key + draftItems.length : key}}>
                        { item.item_forms ? 
                        <ItemCatalog 
                            refPage="cataloguePage"
                            isDraft={false}
                            element={element}
                            data={item}
                            setIsMainFieldsFull={setIsMainFieldsFull}
                            isLastTab={isLastTab}
                            zIndex={1}
                        /> : ''}
                    </div>
                })
            
            :   
                <>
                    <div>{renderCustomFields({title: title, website: website, fontSizeTitle: fontSizeTitle, fontSizeWebsite: fontSizeWebsite})}</div>
                    <img src={currentTemplate()} style={{width: '50%', margin: '0 auto', display: 'block'}} />
                </>

            }
            </div>
            
        :
            <Spin fullscreen={true} />
        }

        <Button onClick={() => changePage(1)} style={{position: 'absolute', zIndex: draftItems.length * 2 + 1, top: '50%', right: '0', marginRight: '10px', height: '40px', borderRadius: '30px'}}>{'>'}</Button>
      
    </AppLayout>
  );
};

export default CataloguePreviewVindix;
