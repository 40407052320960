import { FC, useState, useEffect } from "react";
import AppLayout from "../../common/components/AppLayout/vindix";
import styles from "./productsPage.module.scss";
import ProductsList from "../../products/components/ActiveProductsList";
import { useActions } from "../../../hooks/useActions";
import { GoldOutlined } from '@ant-design/icons';
import { Row, Col, Steps, ConfigProvider, theme } from 'antd';
import {TemplateSelector} from "../components/templateSelector";
import style from "./catalogueVindixPage.module.scss"

const CatalogueVindix: FC = (): JSX.Element => {
  const {
    cleareActiveProductsStore,
    cleareDraftProductsStore,
    clearFilter,
    clearFilterControl,
    clearAdditionalFilter,
    clearAdditionalFilterControl,
  } = useActions();

  useEffect(() => {
    clearFilter();
    clearFilterControl();
    clearAdditionalFilter();
    clearAdditionalFilterControl();

    return () => {
      cleareActiveProductsStore();
      cleareDraftProductsStore();
    };
  }, [
    clearAdditionalFilter,
    clearAdditionalFilterControl,
    clearFilter,
    clearFilterControl,
    cleareActiveProductsStore,
    cleareDraftProductsStore,
  ]);

  <ConfigProvider
    theme={{
        components: {
            Steps: {
                colorPrimary: '#F0b96b',        
            }
        }
    }}
  ></ConfigProvider>

  const steps = [
    {
      title: 'Template',
      content: 'First-content',
    },
    {
      title: 'Customize',
      content: 'Second-content',
    },
    {
      title: 'Select products',
      content: 'Last-content',
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <AppLayout
      title="Catalogues"
      headerLogo={<GoldOutlined />}
    >
      <Row style={{marginBottom: '20px'}}>
        <Col span={12}>
            {/* <Steps className={style.step} current={current} items={items} /> */}
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{textAlign: 'center', paddingTop: '20px'}}>
            <TemplateSelector />
        </Col>
      </Row>
      
      
    </AppLayout>
  );
};

export default CatalogueVindix;
