import { FC, useState, useEffect } from "react";
import AppLayout from "../../common/components/AppLayout/vindix";
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { GoldOutlined } from '@ant-design/icons';
import { Row, Col, Steps, ConfigProvider, theme } from 'antd';
import CustomizeTemplateFormik from "../components/customizeTemplate";
import style from "./catalogueVindixPage.module.scss"

const CustomizeCatalogueVindix: FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

  <ConfigProvider
    theme={{
        components: {
            Steps: {
                colorPrimary: '#F0b96b',        
            }
        }
    }}
  ></ConfigProvider>

  const steps = [
    {
      title: 'Template',
      content: 'First-content',
    },
    {
      title: 'Customize',
      content: 'Second-content',
    },
    {
      title: 'Select products',
      content: 'Last-content',
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));
  
  return (
    <AppLayout
      title="Catalogues"
      headerLogo={<GoldOutlined />}
    >
      <Row style={{marginBottom: '20px'}}>
        <Col span={12}>
            {/* <Steps className={style.step} current={1} items={items} /> */}
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{textAlign: 'center', paddingTop: '20px'}}>
            <CustomizeTemplateFormik navigate={navigate} dispatch={dispatch}/>
        </Col>
      </Row>
      
      
    </AppLayout>
  );
};

export default CustomizeCatalogueVindix;
