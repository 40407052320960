import { FC, useState } from 'react';
// import '../SideBar/sidebar.global.scss';
import styles from './wineSideBar.module.scss';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { signOut } from '../../../../api/auth';
import { NavLink, useLocation } from 'react-router-dom';
import { RoutesNames } from '../../../../routes';
import AppModal from '../AppModal';
import UserCompaniesSelect from '../../../companies/components/UserCompaniesSelect';
import { useActions } from '../../../../hooks/useActions';
import VindixLogo from "../../../common/icons/vindixlogocolor.png";
import { QrcodeOutlined, BlockOutlined, CloudUploadOutlined, LogoutOutlined, UserOutlined, UploadOutlined, FilePdfOutlined } from '@ant-design/icons';
import { ReactComponent as QaCodeIcon } from '../../icons/digital_assets_icon.svg';
import { ReactComponent as ReportsIcon } from '../../icons/reports.svg';
import { ReactComponent as DashboardIcon } from '../../icons/dashboard.svg';

const WineSideBar: FC = (): JSX.Element => {
    const { user } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const [isSignOutModalVisible, setIsSignOutModalVisible] = useState(false);
    const {
        clearFilter,
        clearFilterControl,
        clearAdditionalFilter,
        clearAdditionalFilterControl,
        cleareDraftItemStore,
        cleareDraftItemsStore,
    } = useActions();
    const location = useLocation()
    const { userCompanyId, userCompanyType } = useAppSelector((state) => state.companies);

    return (
        <>
            <aside className={styles.sidebar}>
                <div>
                    <header className={styles.header}>
                        <img src={VindixLogo} className={styles.headerLogo} />
                    </header>
                    <nav className={styles.navbar}>
                        {/* <NavLink to={RoutesNames.DASHBOARD}>
                            <div className="nav__link">
                                <div className="darkRed"><div className={styles.outlinedIcon}><DashboardIcon style={{width: '20px', marginLeft: '3px'}}/></div></div>
                                Dashboard
                            </div>
                        </NavLink> */}
                        <NavLink
                            to={RoutesNames.ITEMS}
                            onClick={() => {
                                clearFilter();
                                clearFilterControl();
                                clearAdditionalFilter();
                                clearAdditionalFilterControl();
                            }}
                        >
                            <div className="nav__link">
                                <div className="darkRed"><BlockOutlined className={styles.outlinedIcon} /></div>
                                My Wines
                            </div>
                        </NavLink>
                        {!user?.is_admin && (
                            <NavLink 
                                to={RoutesNames.ELABEL}
                                onClick={() => {
                                    cleareDraftItemStore();
                                    cleareDraftItemsStore();
                                }}
                            >
                                <div className="nav__link">
                                    <div className="darkRed"><QrcodeOutlined className={styles.outlinedIcon} /></div>
                                    Create e-Label
                                </div>
                            </NavLink>
                        )}
                        <NavLink to={RoutesNames.REPORTS}>
                            <div className="nav__link">
                                <div className="darkRed"><div className={styles.outlinedIcon}><ReportsIcon /></div></div>
                                Reports
                            </div>
                        </NavLink>
                        {!user?.is_admin && (
                            <NavLink to={RoutesNames.UPLOAD}>
                                <div className="nav__link">
                                    <div className="darkRed"><UploadOutlined className={styles.outlinedIcon} /></div>
                                    Upload
                                </div>
                            </NavLink>
                        )}
                        {!user?.is_admin && (
                        <NavLink to="/catalogue">
                            <div className="nav__link">
                                <div className="darkRed"><div className={styles.outlinedIcon}><FilePdfOutlined /></div></div>
                                Catalogues
                            </div>
                        </NavLink>
                        )}
                        {!user?.is_admin && (
                        <NavLink to="/digital_links">
                            <div className="nav__link">
                                <div className="darkRed"><div className={styles.outlinedIcon}><QaCodeIcon /></div></div>
                                Digital Links
                            </div>
                        </NavLink>
                        )}
                        {/*{user?.is_admin || (
                            <NavLink to={RoutesNames.UPLOAD}>
                                <div className="nav__link">
                                    <CloudUploadOutlined className={styles.outlinedIcon} />
                                    Publish
                                </div>
                            </NavLink>
                        )}*/}
                    </nav>
                </div>
                <footer className={styles.footer}>
                    <button
                        className={styles.logout}
                        onClick={() => {
                            setIsSignOutModalVisible(true);
                        }}
                    >
                        <LogoutOutlined className={styles.outlinedIcon} />
                        <span className={styles.logoutTitle}>Logout</span>
                    </button>
                    <div className={styles.user}>
                        {!user?.avatar ? (
                            <UserOutlined className={styles.outlinedIcon} />
                        ) : (
                            <img
                                className={styles.iconAvatar}
                                src={user?.avatar}
                                alt="user avatar"
                            />
                        )}
                        <span className={styles.userTitle}>{user?.email}</span>
                    </div>
                    {!user?.is_admin && <div className={styles.userCompaniesSelect}><UserCompaniesSelect /></div>}
                </footer>
            </aside>

            <AppModal
                visible={isSignOutModalVisible}
                handleOk={() => {
                    dispatch(signOut());
                    setIsSignOutModalVisible(false);
                }}
                handleCancel={() => {
                    setIsSignOutModalVisible(false);
                }}
                buttonOkName="YES"
                buttonCancelName="NO"
                modalInfoText="Are you sure you want logout?"
                onCancel={() => setIsSignOutModalVisible(false)}
            />
        </>
    );
};

export default WineSideBar;